import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import css from './AuthenticationPage.module.css';
import { SocialLoginButton } from '../../components';

import { FacebookLogo, GoogleLogo } from './socialLoginLogos';

export const SocialLoginButtonsMaybe = props => {
  const {
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    submitSignInWithGoogleFirebase,
    submitSignInWithFacebookFirebase,
  } = props;
  const showSocialLogins = showFacebookLogin || showGoogleLogin;

  const handleGoogleAuth = () => submitSignInWithGoogleFirebase();

  const handleFacebookAuth = () => submitSignInWithFacebookFirebase();

  return showSocialLogins ? (
    <div className={css.idpButtons}>
      <div className={css.socialButtonsOr}>
        <span className={css.socialButtonsOrText}>
          <FormattedMessage id="AuthenticationPage.or" />
        </span>
      </div>

      {showFacebookLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={handleFacebookAuth}>
            <span className={css.buttonIcon}>{FacebookLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithFacebook" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithFacebook" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}

      {showGoogleLogin ? (
        <div className={css.socialButtonWrapper}>
          <SocialLoginButton onClick={handleGoogleAuth}>
            <span className={css.buttonIcon}>{GoogleLogo}</span>
            {isLogin ? (
              <FormattedMessage id="AuthenticationPage.loginWithGoogle" />
            ) : (
              <FormattedMessage id="AuthenticationPage.signupWithGoogle" />
            )}
          </SocialLoginButton>
        </div>
      ) : null}
    </div>
  ) : null;
};

SocialLoginButtonsMaybe.propTypes = {
  isLogin: PropTypes.bool,
  showFacebookLogin: PropTypes.bool,
  showGoogleLogin: PropTypes.bool,
  submitSignInWithFacebookFirebase: PropTypes.func,
  submitSignInWithGoogleFirebase: PropTypes.func,
};

SocialLoginButton.defaultProps = {
  isLogin: false,
  showFacebookLogin: false,
  showGoogleLogin: false,
  submitSignInWithFacebookFirebase: null,
  submitSignInWithGoogleFirebase: null,
};

export default SocialLoginButtonsMaybe;
