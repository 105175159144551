/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Heading, LinkTabNavHorizontal } from '../../components';
import { isInvalidAccountCredentials, isSignupEmailTakenError } from '../../util/errors';

import css from './AuthenticationPage.module.css';
import LoginForm from './LoginForm/LoginForm';
import SignupForm from './SignupForm/SignupForm';
import SocialLoginButtonsMaybe from './SocialLoginButtonsMaybe';
import { clearSignupError } from '../../ducks/auth.duck';

export const AuthenticationForms = props => {
  const {
    isLogin,
    showFacebookLogin,
    showGoogleLogin,
    from,
    submitLogin,
    submitSignInWithGoogleFirebase,
    submitSignInWithFacebookFirebase,
    loginError,
    signupError,
    authInProgress,
    submitSignup,
    termsAndConditions,
    socialLoginError,
  } = props;
  const fromState = { state: from ? { from } : null };
  const dispatch = useDispatch();
  const handleClearSignUpError = () => dispatch(clearSignupError());
  const tabs = [
    {
      text: (
        <Heading as={!isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.signupLinkText" />
        </Heading>
      ),
      selected: !isLogin,
      linkProps: {
        name: 'SignupPage',
        to: fromState,
      },
    },
    {
      text: (
        <Heading as={isLogin ? 'h1' : 'h2'} rootClassName={css.tab}>
          <FormattedMessage id="AuthenticationPage.loginLinkText" />
        </Heading>
      ),
      selected: isLogin,
      linkProps: {
        name: 'LoginPage',
        to: fromState,
      },
    },
  ];

  const handleSubmitSignup = values => {
    const { fname, lname, phoneNumber, ...rest } = values;
    const params = {
      firstName: fname.trim(),
      lastName: lname.trim(),
      phoneNumber,
      ...rest,
    };
    submitSignup(params);
  };

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  const signupErrorMessage = (
    <div className={css.error}>
      {isSignupEmailTakenError(signupError) ? (
        <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
      ) : (
        <FormattedMessage id="AuthenticationPage.signupFailed" />
      )}
    </div>
  );

  const socialLoginErrorAccountExisted = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.socialLoginErrorAccountExisted" />
    </div>
  );

  const socialLoginErrorInvalidCredentials = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.socialLoginErrorInvalidCredentials" />
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError = isLogin
    ? errorMessage(loginError, loginErrorMessage)
    : errorMessage(signupError, signupErrorMessage);

  return (
    <div className={css.content}>
      <LinkTabNavHorizontal className={css.tabs} tabs={tabs} />
      {loginOrSignupError}
      {isInvalidAccountCredentials(socialLoginError)
        ? errorMessage(socialLoginError, socialLoginErrorInvalidCredentials)
        : errorMessage(socialLoginError, socialLoginErrorAccountExisted)}

      {isLogin ? (
        <LoginForm className={css.loginForm} onSubmit={submitLogin} inProgress={authInProgress} />
      ) : (
        <SignupForm
          handleClearSignUpError={handleClearSignUpError}
          className={css.signupForm}
          onSubmit={handleSubmitSignup}
          inProgress={authInProgress}
          termsAndConditions={termsAndConditions}
        />
      )}

      <SocialLoginButtonsMaybe
        isLogin={isLogin}
        showFacebookLogin={showFacebookLogin}
        showGoogleLogin={showGoogleLogin}
        from={from}
        submitSignInWithGoogleFirebase={submitSignInWithGoogleFirebase}
        submitSignInWithFacebookFirebase={submitSignInWithFacebookFirebase}
      />
    </div>
  );
};

AuthenticationForms.propTypes = {
  authInProgress: PropTypes.bool,
  from: PropTypes.any,
  isLogin: PropTypes.bool,
  loginError: PropTypes.any,
  showFacebookLogin: PropTypes.bool,
  showGoogleLogin: PropTypes.bool,
  signupError: PropTypes.any,
  socialLoginError: PropTypes.any,
  submitLogin: PropTypes.func,
  submitSignInWithFacebookFirebase: PropTypes.func,
  submitSignInWithGoogleFirebase: PropTypes.func,
  submitSignup: PropTypes.func,
  termsAndConditions: PropTypes.any,
};

AuthenticationForms.defaultProps = {
  authInProgress: false,
  from: null,
  isLogin: false,
  loginError: null,
  showFacebookLogin: false,
  showGoogleLogin: false,
  signupError: null,
  socialLoginError: null,
  submitLogin: null,
  submitSignInWithFacebookFirebase: null,
  submitSignInWithGoogleFirebase: null,
  submitSignup: null,
  termsAndConditions: null,
};

export default AuthenticationForms;
